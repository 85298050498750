<template>
  <div id="content" class="content content-contact-support col-xl-12 mx-auto">
    <div class="container">
      <div class="ria page-title-light">
        Contact support form
      </div>
      <p class="ria page-content-light">
        Tell us about issue and we'll connect you with a customer service representative who can answer any question you have.
      </p>
      <div class="ria page-content-light">
        <form @submit.prevent="onSubmit" autocomplete="off" id="form" enctype="multipart/form-data">
          <div class="form-group">
            <label for="inquiry" class="form-label ria page-content-light mt-1 ml-0">Inquiry *</label>
            <select class="form-control form-control-md" id="inquiry" v-model="contactForm.inquiry" required>
              <option value="" disabled>-- Select inquiry -- </option>
              <option v-for="(inquiry, index) in inquires" :value="index" :key="index">
                  {{ inquiry }}
              </option>
            </select>    
          </div>
        <div>
          <div class="form-group">
            <label for="fieleUpload" class="form-label ria page-content-light mt-1 ml-0"> {{`Please upload an excel file  ${contactForm.inquiry != 0 ? '*' : '' }`}} </label>
            <input type="file" name="fieleUpload" accept=".xlsx" id="fieleUpload" :required="contactForm.inquiry != 0"/>
           </div> 
          <div v-if="contactForm.inquiry == 0">
            <div class="form-group">
              <label for="impact" class="form-label ria page-content-light mt-1 ml-0">Impact *</label>
              <select class="form-control form-control-md" id="impact" v-model="contactForm.impact" required>
                <option value="" disabled>-- Select impact -- </option>
                <option v-for="(impact, index) in impacts" :key="index">
                    {{ impact.name }}
                </option>
              </select>    
            </div>

            <div class="form-group">
              <label for="product" class="form-label ria page-content-light mt-1 ml-0">Product *</label>
              <select class="form-control form-control-md" id="product" v-model="selected.product" required @change="onChangeProduct()">
                <option value="" disabled>-- Select product -- </option>
                <option v-for="(product, index) in products" :key="index" :value="product">
                    {{ appMaps[product.productName] || product.productName }}
                </option>
              </select>    
            </div>
            
            <div class="form-group">
              <label for="apiGroupList" class="form-label ria page-content-light mt-1 ml-0">Affected API *</label>
              <select class="form-control form-control-md" id="apiGroupList" v-model="selected.apiGroup" required @change="onChangeApi()">
                <option value="" disabled>-- Select API -- </option>
                <option v-for="(apiGroupList, name) in apiGroupList" :key="name" >
                    {{ name }}
                </option>
              </select>    
            </div>
            
            <div class="form-group">
              <label for="apiEndpoint" class="form-label ria page-content-light mt-1 ml-0">Affected Endpoint *</label>
              <select class="form-control form-control-md" id="apiEndpoint" v-model="selected.apiEndpoint" required @change="onChangeEndpoint()">
                <option value="" disabled>-- Select Endpoint -- </option>
                <option v-for="(apiEndpoint, index) in apiEndpoints" :key="index" :value="apiEndpoint.name + '-' + apiEndpoint.method">
                    {{ apiEndpoint.description }} - {{ apiEndpoint.method }}
                </option>
              </select>    
            </div>

            <div class="form-gro">
              <label for="requestResponse" class="form-label ria page-content-light mt-1 ml-0">APIs Endpoints Request / Response</label>
              <textarea type="text" v-model="contactForm.api_endpoints_req_resp" class="form-control form-control-md" id="requestResponse" rows=4> </textarea>
            </div>                 
          </div>

          <div class="form-group">
            <label for="summaryContent" class="form-label ria page-content-light mt-1 ml-0">Summary Content</label>
            <input type="text" v-model="contactForm.summaryContent" class="form-control form-control-md" id="summaryContent">
          </div>

          <div class="form-group">
            <label for="partnerName" class="form-label ria page-content-light mt-1 ml-0">Partner Name</label>
            <input type="text" v-model="contactForm.partnerName" class="form-control form-control-md" id="partnerName">
          </div>

          <div class="form-group">
              <label for="description" class="form-label ria page-content-light mt-1 ml-0">Additional Comments</label>
              <textarea type="text" v-model="contactForm.additional_comments" class="form-control form-control-md" id="description" rows=4> </textarea>
          </div>
        </div>
        <button class="btn mt-4 ria page-content-light btn-block btn-orange-rounded">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from '../plugin/AxiosService';
import Constant from '../plugin/Constant';
import Utils from "../plugin/Utils";
let _ = require('lodash'); 
export default {

  props: [ 'environment' ],

  data: function () {
    return {
      apiListAll: '',
      impacts: [
        { name: 'High' },
        { name: 'Medium' },
        { name: 'Low' }
      ],
      products: [],
      apiGroupList: '',
      apiEndpoints: '',
      selected: {
        product: '',
        apiGroup: '',
        apiEndpoint: ''
      },
      appMaps : {
      'send-money': 'Send Money',
      'paying': 'Pay Money'
    },
      contactForm: {
        riaAgentId: 1,
        environment: 'UAT',
        impact: '',
        UserEmail: ''
      },
      inquires: [
        "API/Endpoint inquiry",
        "Integration Plan Completed",
        "Portal Test Cases Completed"
      ]
    }
  },

  methods: {
    onSubmit() {
      this.contactForm.inquiry = this.inquires[this.contactForm.inquiry];
      let contentType = null;
      let requestBody = this.contactForm;
      let url = Constant.url.POST_CONTACT_SUPPORT
      const xlFile = document.getElementById('fieleUpload');
      
      if(xlFile?.files.length > 0){
        requestBody =  new FormData();
        contentType = "multipart/form-data";
        url = Constant.url.POST_CONTACT_SUPPORT_WITH_ATTACHMENT
        requestBody.append('contactForm', JSON.stringify(this.contactForm));
        requestBody.append('xlsx', xlFile.files[0]);
      } 

      AxiosService.post(url, requestBody, contentType).then(
        (response) => {
          if (response.responseCode == "00" && response.responseMsg) {
              $.gritter.add({
              title: "Success",
              text: response.responseMsg,
            });
            window.location.assign("/magnolia/dashboard");
          } else {
            $.gritter.add({
              title: "Error",
              text: response.responseMsg,
            });
          }
        }
      );
    },

    getUserEmail() {
      let enc = Utils.encryptStorage().getSession();
      return enc.getItem(Constant.config.USER_ID);
    },

    getUserProducts() {
      let enc = Utils.encryptStorage().getSession();
      let userConfig = enc.getItem(Constant.config.USER_CONFIG);
      
      userConfig['User Subscriptions'].forEach(element => {
        var result = this.products.find(obj => {
          return obj.productName === element['Product Name'];
        });
        if(result === undefined){
            this.products.push({
              productName: element['Product Name'],
              subscriptionName: element['Subscription Name']
            });
        }
      });
    },

    onChangeProduct() {
      this.getMethodList(this.selected.product);
      this.contactForm.product = this.selected.product.productName;
      this.selected.apiGroup = this.selected.apiEndpoint = this.apiEndpoints = this.apiGroupList = '';
      this.onChangeEndpoint();
    },

    onChangeApi() {
      this.apiEndpoints = this.apiGroupList[this.selected.apiGroup];
      this.selected.apiEndpoint = this.apiEndpoint = '';
      this.onChangeEndpoint();
    },

    onChangeEndpoint() {
      this.contactForm.affected_APIs = this.selected.apiGroup;
      this.contactForm.affected_endpoints = this.selected.apiEndpoint;
    },

    getMethodList (product) {
      AxiosService.get(Constant.url.GET_API_GROUP.replace("{productName}", product.productName).replace("{subscriptionName}", product.subscriptionName))
        .then((result) => {
          this.apiGroupList = result;
        });
    },

    getAndSetAgentId() {
      let enc = Utils.encryptStorage().getSession();
      let userConfig = enc.getItem(Constant.config.USER_CONFIG);
      // get agentId from user config
      let riaAgent = JSON.parse(userConfig.note)['ria-Agents'][0];
      riaAgent = riaAgent[Object.keys(riaAgent)[0]];
      // set(replace) agentId
      this.contactForm.riaAgentId = riaAgent;
    },
  },

  created() {
    this.contactForm.UserEmail = this.getUserEmail();
    this.getUserProducts();
    this.getAndSetAgentId();
  },

  mounted() {
    swtichTheme.init();
  },
}
</script>

<style scoped>
.content-contact-support .container {
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 3rem;
  max-width: 36rem;

}
.btn-orange-rounded {
  background-color: rgb(255, 97, 0);
  border-radius: 15px;
}
.content-contact-support .ria.page-content-light,
.content-contact-support .ria.page-content-dark,
.content-contact-support .page-title-light,
.content-contact-support .page-title-dark {
  margin-left: 0px !important;
}
</style>
